/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = Gateway, 1 = Button, 2 = Led, 3 = MonoLight, 11 = Rgb, 12 = TunableWhite, 15 = AnalogSensorModel, 16 = CbShutter, 20 = Scheduler, 21 = Timer, 23 = Threshold, 24 = LinkTranslator, 25 = BinarySensor, 26 = PergolaProtection, 27 = CbShutterLouvres, -1 = Unknown
 */
export enum ModelTypeIds {
    Gateway = 0,
    Button = 1,
    Led = 2,
    MonoLight = 3,
    Rgb = 11,
    TunableWhite = 12,
    AnalogSensorModel = 15,
    CbShutter = 16,
    Scheduler = 20,
    Timer = 21,
    Threshold = 23,
    LinkTranslator = 24,
    BinarySensor = 25,
    PergolaProtection = 26,
    CbShutterLouvres = 27,
    Unknown = -1
}

