/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * -524288 = QueueFull, -458753 = FunctionsInvalidArgument, -458752 = FunctionsNotDefined, -393216 = SerializeMemoryOverflow, -327685 = ModelNoModels, -327684 = ModelWrongLinkSize, -327683 = ModelInvalid, -327682 = ModelNotSupported, -327681 = ModelScenesFull, -327680 = ModelHeapFull, -262147 = MessageFifoBadFrame, -262146 = MessageFifoFull, -262145 = MessageFifoEmpty, -262144 = MessagesRxFlashBusy, -196609 = EventsNoMoreRoom, -196608 = EventsOutputFifoFull, -131078 = NvmFlashVersionMismatch, -131076 = NvmAccessTimeout, -131074 = NvmTooBig, -131073 = NvmUninitialized, -131072 = NvmNoArea, -65539 = HardwareIdNotSupported, -65538 = HardwareHeapFull, -65537 = HardwareVariantNotSupported, -65536 = ErrorNoHwDefined, -258 = ErrorNotSupported, -257 = ErrorNullPointer, -1 = Error
 */
export enum CanaryStatusCodes {
    QueueFull = 'QueueFull',
    FunctionsInvalidArgument = 'FunctionsInvalidArgument',
    FunctionsNotDefined = 'FunctionsNotDefined',
    SerializeMemoryOverflow = 'SerializeMemoryOverflow',
    ModelNoModels = 'ModelNoModels',
    ModelWrongLinkSize = 'ModelWrongLinkSize',
    ModelInvalid = 'ModelInvalid',
    ModelNotSupported = 'ModelNotSupported',
    ModelScenesFull = 'ModelScenesFull',
    ModelHeapFull = 'ModelHeapFull',
    MessageFifoBadFrame = 'MessageFifoBadFrame',
    MessageFifoFull = 'MessageFifoFull',
    MessageFifoEmpty = 'MessageFifoEmpty',
    MessagesRxFlashBusy = 'MessagesRxFlashBusy',
    EventsNoMoreRoom = 'EventsNoMoreRoom',
    EventsOutputFifoFull = 'EventsOutputFifoFull',
    NvmFlashVersionMismatch = 'NvmFlashVersionMismatch',
    NvmAccessTimeout = 'NvmAccessTimeout',
    NvmTooBig = 'NvmTooBig',
    NvmUninitialized = 'NvmUninitialized',
    NvmNoArea = 'NvmNoArea',
    HardwareIdNotSupported = 'HardwareIdNotSupported',
    HardwareHeapFull = 'HardwareHeapFull',
    HardwareVariantNotSupported = 'HardwareVariantNotSupported',
    ErrorNoHwDefined = 'ErrorNoHwDefined',
    ErrorNotSupported = 'ErrorNotSupported',
    ErrorNullPointer = 'ErrorNullPointer',
    Error = 'Error'
}

